import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './IpInfo.css';

// Import Leaflet marker icons
import L from 'leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIconRetina from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIconRetina,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

const IpInfo = () => {
  const [ipInfo, setIpInfo] = useState(null);
  const [error, setError] = useState(null);

  // Replace with your Heroku app URL
  const API_URL = 'https://react-app-testt-679236feea15.herokuapp.com/api/ipinfo';

  useEffect(() => {
    const fetchIpInfo = async () => {
      try {
        // Fetch IP information from the API endpoint
        const response = await axios.get(API_URL, { timeout: 10000 });
        console.log('API Response:', response.data); // Log the response for debugging
        setIpInfo(response.data);
      } catch (err) {
        // Handle errors and set the error message
        console.error('Error details:', err.response ? err.response.data : err.message);
        setError(err.message || 'Network Error');
      }
    };

    fetchIpInfo();
  }, []);

  // Convert IP address to decimal format
  const ipToDecimal = (ip) => {
    return ip.split('.').reduce((acc, octet, index) => {
      return acc + octet * Math.pow(256, 3 - index);
    }, 0);
  };

  // Render loading or error message if data is not yet available
  if (error) {
    return <div className="container">Error: {error}</div>;
  }

  if (!ipInfo) {
    return <div className="container">Loading...</div>;
  }

  // Extract IP address and convert it to decimal
  const ipAddress = ipInfo.traits?.ip_address || 'N/A';
  const ipDecimal = ipAddress ? ipToDecimal(ipAddress) : 'N/A';

  return (
    <div className="container">
      <h1>What is my IP address?</h1>
      <p className="ip-address">{ipAddress}</p>
      <p className="description">The best tool to find your own IP address, and information about it.</p>
      <div className="info-section">
        <h2 className="info-section-heading">What do we know about this IP address?</h2>
        <div className="info-item">
          <span className="label">IP Address:</span>
          <span className="value">{ipAddress}</span>
        </div>
        <div className="info-item">
          <span className="label">IP Address (Decimal):</span>
          <span className="value">{ipDecimal}</span>
        </div>
        <div className="info-item">
          <span className="label">Metro Code:</span>
          <span className="value">{ipInfo.location?.metro_code || 'N/A'}</span>
        </div>
        <div className="info-item">
          <span className="label">Postal Code:</span>
          <span className="value">{ipInfo.postal?.code || 'N/A'}</span>
        </div>
        <div className="info-item">
          <span className="label">Latitude:</span>
          <span className="value">{ipInfo.location?.latitude || 'N/A'}</span>
        </div>
        <div className="info-item">
          <span className="label">Longitude:</span>
          <span className="value">{ipInfo.location?.longitude || 'N/A'}</span>
        </div>
        <div className="info-item">
          <span className="label">Country:</span>
          <span className="value">{ipInfo.country?.names?.en || 'N/A'}</span>
        </div>
        <div className="info-item">
          <span className="label">Region:</span>
          <span className="value">{ipInfo.subdivisions?.[0]?.names?.en || 'N/A'}</span>
        </div>
        <div className="info-item">
          <span className="label">City:</span>
          <span className="value">{ipInfo.city?.names?.en || 'N/A'}</span>
        </div>
        <div className="info-item">
          <span className="label">Time Zone:</span>
          <span className="value">{ipInfo.location?.time_zone || 'N/A'}</span>
        </div>
      </div>
      {ipInfo.location?.latitude && ipInfo.location?.longitude && (
        <>
          <div className="map-divider"></div>
          <div className="map-container">
            <MapContainer center={[ipInfo.location.latitude, ipInfo.location.longitude]} zoom={13} style={{ height: '100%', width: '100%' }}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker position={[ipInfo.location.latitude, ipInfo.location.longitude]}>
                <Popup>
                  {ipInfo.city?.names?.en || 'Unknown City'}, {ipInfo.country?.names?.en || 'Unknown Country'}
                </Popup>
              </Marker>
            </MapContainer>
          </div>
        </>
      )}
      <p className="credit">
        This information is provided from the GeoLite2 database created by MaxMind, available from <a href="https://www.maxmind.com">www.maxmind.com</a>
      </p>
    </div>
  );
};

export default IpInfo;
// This code uses GeoLite2 data created by MaxMind, available from https://www.maxmind.com
